












































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import ClientForm from "../ClientForm.vue";
import { Business, Role } from "@/types";
import { api } from "@/util/axios";
import { filter } from "vue/types/umd";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("FEEDBACK_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {},
  name: "FeedbackList",
  props: {
    clientEmail: {
      default: null,
    },

    startDate: {
      default: null,
    },
    endDate: {
      default: null,
    },
    selectedRatingType: {
      default: null,
    },
    exportCount: {
      default: 0,
    },
  },
  data: () => ({
    client: undefined,
    showClientDialog: false,
    showDeleteDialog: false,
    selectedGender: "All",
    selectedTime: "Most Recent",
    selectedStaff: null, // will now hold a string value (staff name)
    showOnlyCompleted: false, // <-- new checkbox flag
    startDateMenu: false,
    endDateMenu: false,
    ratingTypes: [
      { text: "All Ratings", value: "all" },
      { text: "Good Ratings (>3)", value: "good" },
      { text: "Bad Ratings (<3)", value: "bad" },
      { text: "Neutral Ratings (=3)", value: "neutral" },
    ],
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Order",
        align: "start",
        sortable: true,
        value: "service",
      },
      { text: "Email", value: "clientEmail" },
      { text: "Rating", value: "rating" },
      { text: "Staff", value: "staff" },
      { text: "Action", value: "action" },
    ],
    apiUrl: VUE_APP_API_URL,
    options: {} as { page: number },
    editClientDialog: false,
    exporting: false,
  }),
  watch: {
    exportCount() {
      this._export();
    },
    role: "fetchClients",
    options: {
      handler() {
        this.fetchClients();
      },
      deep: true,
    },
    selectedRatingType(v) {
      this.fetchClients();
    },
    startDate() {
      this.fetchClients();
    },
    endDate() {
      this.fetchClients();
    },
    selectedStaff() {
      this.fetchClients();
    },
  },
  computed: {
    ...clientGetters(["feedbackPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    staffOptions(): string[] {
      // Compute unique staff names from feedback data.
      const staffArr = this.feedbackPage.docs
        .map((item) => this.getStaff(item))
        .filter((item) => item.filterType == "staff");
      return Array.from(new Set(staffArr));
    },
  },
  filters: {
    maskPhone(phone: string) {
      if (!phone) return "";
      let masked = "";
      for (let i = 0; i < phone.length; i++) {
        if (i <= 3 || i >= 9) {
          masked += phone[i];
        } else {
          masked += "*";
        }
      }
      return masked;
    },
  },
  methods: {
    ...clientActions(["fetchClientFeedback", "deleteClient", "exportClients"]),
    viewClient(id: number) {
      this.$router.push(`/client/${id}`);
    },
    resend(id: string) {
      api.get(`/v1/feedback/resend?feedbackId=${id}`).then(() => {
        this.$store.dispatch(
          "setToast",
          {
            title: "Success",
            type: "success",
            text: "Feedback resent successfully",
          },
          { root: true }
        );
      });
    },
    closeModal() {
      this.fetchClients();
      this.showClientDialog = false;
      this.editClientDialog = false;
    },
    fetchClients() {
      if (this.role) {
        let limit = this.options.itemsPerPage;
        let page = this.options.page;
        if (limit === -1) limit = 1000000;
        const bid = (this.role.business as Business)?._id;

        let params = `?business=${bid}&page=${page || 1}&limit=${limit}`;

        // If "Show only completed" is checked, set ratingType to "all"
        if (this.showOnlyCompleted) {
          params += `&ratingType=all`;
        } else if (this.selectedRatingType) {
          params += `&ratingType=${this.selectedRatingType}`;
        }

        // Add date filters
        if (this.startDate) {
          params += `&startDate=${this.startDate}`;
        }
        if (this.endDate) {
          params += `&endDate=${this.endDate}`;
        }

        // Use the staff name directly
        if (this.selectedStaff) {
          params += `&staffId=${this.selectedStaff}`;
        }

        if (this.clientEmail) {
          params += `&clientEmail=${this.clientEmail}`;
        }

        this.fetchClientFeedback(params);
      }
    },
    searchClient(q: string) {
      if (q) {
        const bid = (this.role.business as Business)?._id;
        const params = `?businessId=${bid}&q=${q}`;
        this.fetchClientFeedback(params);
      }
    },
    _export() {
      const params = `?businessId=${(this.role.business as Business)?._id}`;
      this.exporting = true;
      this.exportClients(params).then((res) => {
        this.exporting = false;
        if (res.data) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "clients.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      });
    },
    exportCsv() {
      const tableItems = this.feedbackPage.docs;
      if (!tableItems || !tableItems.length) {
        console.error("No client ratings to export");
        return;
      }
      this.downloadCsv(
        tableItems.map((x: any) => {
          return {
            "Client ID": x._id,
            "Full Name": x.fullName,
            Email: x.email,
            Rating: x.rating,
            "Created At": x.createdAt,
            Staff: x.service.user.displayName,
          };
        }),
        "client_ratings.csv"
      );
    },
    downloadCsv(data: Record<string, any>[], filename = "export.csv") {
      const headers = Object.keys(data[0]);
      const csvRows = [
        headers.join(","),
        ...data.map((row) =>
          headers
            .map((header) => {
              const cell = row[header];
              if (
                typeof cell === "string" &&
                (cell.includes(",") || cell.includes('"'))
              ) {
                return `"${cell.replace(/"/g, '""')}"`;
              }
              return cell?.toString() ?? "";
            })
            .join(",")
        ),
      ];
      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    getStaff(item) {
      if (item.service.services && item.service.services.length) {
        return {
          name: item.service.services[0].staff.fullName,
          email: item.service.services[0].staff.email,
          staffId: item.service.services[0].staff._id,
          filterType: "staff",
        };
      }
      return {
        name: item.service.user.displayName,
        email: item.service.user.email,
        filterType: "user",
      };
    },

    getStaffEmail(item) {
      if (item.service.services && item.service.services.length) {
        return item.service.services[0].staff.email;
      }
      return item.service.user.email;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("FEEDBACK_LIST")) {
      this.$store.registerModule("FEEDBACK_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FEEDBACK_LIST");
  },
});
