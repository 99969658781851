




























































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("GOOGLE_REVIEW_LIST");

export default Vue.extend<any, any, any, any>({
  components: {},
  name: "GoogleReviewList",
  props: {
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    review: undefined,
    editReviewDialog: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      { text: "Reviewer", value: "reviewer" },
      { text: "Rating", value: "rating" },
      { text: "Comment", value: "comment" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  computed: {
    ...clientGetters(["googleReviewPage"]),
    // googleReviewPage() {
    //   return this.$store.state.GOOGLE_REVIEW_LIST.googleReviewPage;
    // },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["fetchGoogleReviews"]),
    fetchReviews() {
      if (this.role) {
        const businessId = (this.role.business as Business)?._id;
        const params: any = { businessId };
        if (this.startDate) params.startDate = this.startDate;
        if (this.endDate) params.endDate = this.endDate;
        try {
          this.fetchGoogleReviews(params);
        } catch (error) {
          console.error("Error fetching Google reviews:", error);
        }
      }
    },
  },
  watch: {
    startDate: "fetchReviews",
    endDate: "fetchReviews",
  },
  beforeCreate() {
    // if (!this.$store.hasModule("GOOGLE_REVIEW_LIST")) {
    //   this.$store.registerModule("GOOGLE_REVIEW_LIST", clientStoreModule);
    // }

    this.$store.registerModule("GOOGLE_REVIEW_LIST", clientStoreModule);
  },
  beforeDestroy() {
    this.$store.unregisterModule("GOOGLE_REVIEW_LIST");
  },
  mounted() {
    this.fetchReviews();
  },
});
