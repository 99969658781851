




























































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import ClientForm from "../ClientForm.vue";
import { Business, Role } from "@/types";
import UpdateClientForm from "./UpdateClientForm.vue";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENT_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ClientForm, UpdateClientForm },
  name: "ClientList",
  data: () => ({
    client: undefined,
    showClientDialog: false,
    showDeleteDialog: false,
    selectedGender: "All",
    selectedTime: "Most Recent",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "fullName",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Action", value: "action" },
    ],
    apiUrl: VUE_APP_API_URL,
    options: {} as { page: number },
    editClientDialog: false,
    exporting: false,
  }),
  watch: {
    role: "fetchClients",
    options: {
      handler() {
        this.fetchClients();
      },
      deep: true,
    },
  },
  computed: {
    ...clientGetters(["clientPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  filters: {
    maskPhone(phone: string) {
      if (!phone) return "";
      let masked = "";
      for (let i = 0; i < phone.length; i++) {
        if (i <= 3 || i >= 9) {
          masked += phone[i];
        } else {
          masked += "*";
        }
      }

      return masked;
    },
  },
  methods: {
    ...clientActions(["fetchClientList", "deleteClient", "exportClients"]),
    viewClient(id: number) {
      this.$router.push(`/client/${id}`);
    },
    closeModal() {
      this.fetchClients();
      this.showClientDialog = false;
      this.editClientDialog = false;
    },
    fetchClients() {
      if (this.role) {
        let limit = this.options.itemsPerPage;
        let page = this.options.page;
        if (limit === -1) limit = 1000000;
        const bid = (this.role.business as Business)?._id;
        let params = `?businessId=${bid}&page=${page || 1}&limit=${limit}`;
        this.fetchClientList(params);
      }
    },
    searchClient(q: string) {
      if (q) {
        const bid = (this.role.business as Business)?._id;
        const params = `?businessId=${bid}&q=${q}`;
        this.fetchClientList(params);
      }
    },
    _export() {
      const params = `?businessId=${(this.role.business as Business)?._id}`;
      this.exporting = true;
      this.exportClients(params).then((res) => {
        this.exporting = false;
        if (res.data) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "clients.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_LIST")) {
      this.$store.registerModule("CLIENT_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_LIST");
  },
});
