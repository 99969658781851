var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"eager":"","width":"500"},model:{value:(_vm.editReviewDialog),callback:function ($$v) {_vm.editReviewDialog=$$v},expression:"editReviewDialog"}},[(_vm.review)?_c('v-card',{staticClass:"px-4 py-4",staticStyle:{"height":"500px"}},[(_vm.review)?_c('div',[_c('div',[_c('b',[_vm._v("Comments")])]),_vm._v(" "+_vm._s(_vm.review.comment || "No comment")+" "),_c('br'),_c('hr'),_c('br'),_c('div',[_c('b',[_vm._v("Rating")])]),_c('div',[_vm._v(" "+_vm._s(_vm.review.rating)+" ")]),_c('br'),_c('hr'),_c('br'),_c('div',[_c('b',[_vm._v("Reviewer")])]),_c('div',[_vm._v(" "+_vm._s(_vm.review.reviewer)+" ")])]):_vm._e()]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.googleReviewPage.docs,"options":_vm.options,"server-items-length":_vm.googleReviewPage.total,"no-data-text":"No reviews to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.createdAt,"D/MM/YYYY"))+" "+_vm._s(_vm._f("readableDate")(item.createdAt,"h:mm a"))+" ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rating == -1 ? "N/A" : item.rating)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.review = item;
          _vm.editReviewDialog = true;}}},[_vm._v(" View ")]),_vm._v("   ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }